import React from "react"
import Testimonials from "../home/testimonials/Testimonials"
import Subscription from "../home/subscriptionSection/Subscription"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import "./about.scss"
import { graphql, useStaticQuery } from "gatsby"

const Aboutaia = () => {
  const backgroundImage = useStaticQuery(
    graphql`
      query {
        background1: file(
          relativePath: { eq: "mission-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 584) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background2: file(
          relativePath: { eq: "vision-bg.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 455) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const BGImage1 =
    backgroundImage.background1.childImageSharp.fluid
  const BGImage2 =
    backgroundImage.background2.childImageSharp.fluid

  return (
    <div>
      {/* --- About Section --- */}
      <section className="about_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-md-7 col-lg-7">
              <div className="left-box">
                <div className="section-header">
                  <h2>About Ace Industrial Academy</h2>
                </div>
                <div className="content">
                  <p>
                    Ace Industrial Academy is an education
                    organization specializing in today's
                    most in-demand skills for Singapore's
                    Built Environment.
                  </p>
                  <p>
                    Construction IT continues to evolve in
                    its people, process and technology. As
                    individuals and companies struggle with
                    the increasing technological demands of
                    the industry, Ace Industrial Academy
                    bridges that skills gap by providing
                    highly practical and innovative learning
                    experiences - so you remain focused on
                    your core business.
                  </p>
                  <p>
                    Ace Industrial Academy is an Autodesk
                    Training and Certification Center, and
                    Bentley Training Partner. In 2022, we
                    are recognized as a SkillsFuture
                    Singapore (SSG) Approved Training
                    Provider, where we deliver
                    industry-relevant courses that is
                    aligned to the Skills Framework for the
                    Built Environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-5 col-lg-5">
              <div className="right-box">
                <div className="content-box box-one">
                  <div class="media">
                    <StaticImage
                      src="../../assets/images/about/about-icon-1.svg"
                      className="img-tag"
                      alt="Icons"
                    />
                    <div class="media-body">
                      <p>ACRA-registered Name</p>
                      <h6>
                        Ace Industrial Academy Pte. Ltd.
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="main-img">
                  <StaticImage
                    src="../../assets/images/about/about-1.png"
                    alt="About"
                  />
                </div>
                <div className="content-box box-two">
                  <div class="media">
                    <StaticImage
                      src="../../assets/images/about/about-icon-2.svg"
                      className="img-tag"
                      alt="Icons"
                    />
                    <div class="media-body">
                      <p>UEN</p>
                      <h6>200710151K</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --- counter --- */}
      <section className="counter_section">
        {/* <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-4 col-lg-4">
              <div className="inner-box">
                <div className="counter-number">500+</div>
                <p>Available Courses</p>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-lg-4">
              <div className="inner-box">
                <div className="counter-number">500+</div>
                <p>Experienced Trainers</p>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-lg-4">
              <div className="inner-box">
                <div className="counter-number">500+</div>
                <p>Satisfied Students</p>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      {/* --- mission --- */}
      <BackgroundImage
        className="mission-bg"
        fluid={BGImage1}
      >
        <section className="mission_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-6 col-lg-6">
                <div className="left-box">
                  <div className="section-header">
                    <h2>Our Mission</h2>
                  </div>
                  <div className="content">
                    <p>
                      Ace Industrial Academy is the place
                      for Built Environment professionals
                      explore, learn and grow. Here, we
                      create a conducive environment for
                      learning, knowledge exchange, and to
                      connect with like minded
                      professionals. The end goal is a
                      professional with an increased sense
                      of self-fulfilment and confidence
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6">
                <div className="right-box">
                  <StaticImage
                    src="../../assets/images/about/mission.svg"
                    alt="About"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      {/* --- core value --- */}
      <section className="core_value">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-header text-center">
                <h2>Our Core Values</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-lg-6">
              <div className="core-box">
                <StaticImage
                  src="../../assets/images/about/core1.svg"
                  alt="Core Value"
                />
                <h3>Practical and Applicable</h3>
                <p>
                  We learn best knowing what is taught is
                  useful to us. Ace Industrial Academy
                  considers the relevance of content, curate
                  knowledge and think about how you will
                  effectively integrate it to your workplace
                  and projects.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-lg-6">
              <div className="core-box">
                <StaticImage
                  src="../../assets/images/about/core2.svg"
                  alt="Core Value"
                />
                <h3>Safe Environment</h3>
                <p>
                  Every learner’s prior experience is
                  acknowledged and respected. We seek to
                  create an environment where learners are
                  challenged and engaged in a safe and
                  respectful manner
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --- Vision --- */}
      <BackgroundImage
        className="vision-bg"
        fluid={BGImage2}
      >
        <section className="vision_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-md-6 col-lg-6">
                <div className="left-box">
                  <StaticImage
                    src="../../assets/images/about/vision.svg"
                    alt="About"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-lg-6">
                <div className="right-box">
                  <div className="section-header">
                    <h2>Our Vision</h2>
                  </div>
                  <div className="content">
                    <p>
                      To establish Ace Industrial Academy as
                      the leading education organization of
                      the Built Environment, and uplift the
                      digital competencies of Built
                      Environment professionals through
                      practical and industry relevant
                      courses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
      <Testimonials />
      <Subscription />
    </div>
  )
}

export default Aboutaia
