import React from "react"
import "./subscription.scss"

const Subscription = () => {
  return (
    <div className="news_letter">
      <div className="container">
        <div className="row_main">
          <h2 className="newsletter_title">
            Be among the first to know!
          </h2>
          <p className="newsletter_description">
            Get updates on new courses, blogs and discounts
          </p>
          <div className="subs_form">
            <input
              type="text"
              name="name"
              placeholder="Enter your Email"
              className="subscription_text"
            />
            <input
              className="submit_btn"
              type="submit"
              value="Subscribe"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscription
