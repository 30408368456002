import React from "react"
import "./testimony.scss"
import CarouselSlider from "../../slider/Slider"
import Testimony from "./testimony/Testimony"
import { useMediaQuery } from "react-responsive"
import miscl from "../../../data/miscl.json"

const Testimonials = () => {
  const isTablet = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const reviews = miscl.testimonial

  return (
    <div className="testimonial">
      <div className="container">
        <h1 className="testimonial_title">
          See what past and current Students say about us
        </h1>
        <div className="row_main">
          <CarouselSlider
            slidesToShow={isTablet ? 1 : 2}
            slidesToScroll={isTablet ? 1 : 1}
            showSlideDots={true}
          >
            {reviews?.map((review, i) => (
              <Testimony key={i} review={review} />
            ))}
          </CarouselSlider>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
