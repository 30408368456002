import React from "react"
import About from "../components/aboutAia/About-aia"

const Aboutaia = () => {
  return (
    <div>
      <About />
    </div>
  )
}
export default Aboutaia
