import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import "./style.scss"

const Testimony = (props) => {
  const { review, fromDetailPage } = props

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(jpg|jpeg|png|gif)/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 45, quality: 100)
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(edge =>
    edge.node.relativePath.includes(review.image)
  )?.node.childImageSharp.gatsbyImageData

  return (
    <div className={`testimonialdata ${fromDetailPage ? "reviewWithoutPadding" : ""}`}>
      <div className="testmonial_review">
        <StaticImage src="../../../../assets/images/Star.svg" alt="Star" />
        <StaticImage src="../../../../assets/images/Star.svg" alt="Star" />
        <StaticImage src="../../../../assets/images/Star.svg" alt="Star" />
        <StaticImage src="../../../../assets/images/Star.svg" alt="Star" />
        <StaticImage src="../../../../assets/images/Star.svg" alt="Star" />
      </div>

      <div className="testimonial_content">
        <p>{review.content}</p>
      </div>

      <div className="reviwer_profile">
        <div className="client_img">
          {image ? (
            <GatsbyImage image={image} alt={`${review.person} photo`} />
          ) : (
            <p>Image not found</p>
          )}
        </div>

        <div className="reviwername_designation">
          <h4 className="reviwer_name">{review.person}</h4>
          <h6 className="reviwer_designnation">
            {review.role} Student
          </h6>
        </div>
      </div>
    </div>
  )
}
export default Testimony