import React from "react"
import Slider from "react-slick"
import "./slider.scss"
import { StaticImage } from "gatsby-plugin-image"

const CarouselSlider = (props) => {
  const {
    children,
    slidesToShow,
    slidesToScroll,
    showSlideDots,
  } = props

  const NextArrow = (props) => {
    const { className, onClick } = props

    return (
      <div
        className={`${className} nextBtnCover`}
        onClick={onClick}
      >
        <StaticImage
          src="../../assets/images/forward-arrow.png"
          alt=""
        />
      </div>
    )
  }

  const PrevArrow = (props) => {
    const { className, onClick } = props

    return (
      <div
        className={`${className} prevBtnCover`}
        onClick={onClick}
      >
        <StaticImage
          src="../../assets/images/back-arrow.png"
          alt=""
        />
      </div>
    )
  }

  const settings = {
    dots: showSlideDots,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <div className="sliderContainer">
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default CarouselSlider
